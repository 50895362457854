import React, { useRef, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import typography from "../../styles/typography"
import richText from "../../utils/richText"

const Wrapper = styled.div`
  padding: 24px;

  flex: 1 0 50%;

  @media ${breakpoints.tablet} {
    max-width: 400px;

    margin: 0 auto;
  }
`

const Title = styled.h1`
  ${typography.featuredLarge.mobile}
  margin: 0;
  color: ${colors.creamLight};

  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.creamLight};
  margin-bottom: 32px;

  @media ${breakpoints.tablet} {
    ${typography.featuredLarge.desktop}
  }
`

const Description = styled.div`
  color: ${colors.creamLight};
  font-size: 14px;

  margin: 0;
`
const MetaLarge = styled.p`
  ${typography.metaLarge.desktop}

  color: ${colors.creamLight};

  margin: 0;
`

const AddToCart = styled.button`
  appearance: none;
  border: 0;
  background: ${colors.orangeDark};

  color: ${colors.firDark};

  padding: 20px;
  margin: 32px 0;

  ${typography.buttonLabel.desktop}

  width: 100%;

  cursor: pointer;
`

const Divider = styled.hr`
  border: 1px solid ${colors.creamLight};
`

export default function MerchDetails({
  title,
  description,
  price,
  onAddToCart,
}) {

  const [addedToCart, setAddedToCart] = useState(false)
  const timeoutRef: React.MutableRefObject<null | NodeJS.Timeout> = useRef(null)

  function onClickAddToCart() {
    setAddedToCart(true)
    onAddToCart()

    timeoutRef.current = setTimeout(() => {
      setAddedToCart(false)
    }, 1000)
  }

  return (
    <Wrapper>
      <MetaLarge>DRØMME</MetaLarge>
      <Title>{title}</Title>
      <Description>{richText(description)}</Description>
      <AddToCart onClick={onClickAddToCart}>
        {addedToCart
          ? "Added!"
          : `Add to Cart - 
        ${new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(price)}`}
      </AddToCart>
      <Divider />
    </Wrapper>
  )
}
