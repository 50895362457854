import { graphql } from "gatsby"
import React from "react"
import ProductSlideshow from "../components/product/ProductSlideshow"
import Layout from "../components/layout"
import Merch from "../components/merch/Merch"
import IngredientHighlights from "../components/product/IngredientHighlight"
import loadable from "@loadable/component"
import Seo from "../components/seo"


// query of contentful merch
export const query = graphql`
  query ($id: String) {
    contentfulMerch(id: { eq: $id }) {
      id
      title
      shopifyId
      description {
        raw
      }
      merchImages {
        gatsbyImage(width: 640, height: 800, placeholder: BLURRED)
      }
      merch {
        variants {
          price
          shopifyId
          storefrontId
        }
      }
    }
  }
`

// export const query = graphql`
//   query ($id: String) {
//     contentfulProduct(id: { eq: $id }) {
//       id
//       productTitle
//       shopifyId
//       productType
//       productDescription {
//         raw
//       }
//       productImages {
//         gatsbyImage(width: 640, height: 800, placeholder: BLURRED)
//       }
//       product {
//         variants {
//           price
//           shopifyId
//           storefrontId
//         }
//       }
//     }
//   }
// `


export default function MerchTemplate({ data }) {

  const { contentfulMerch: item } = data  

  return (
    <Layout>
    <Seo title= "Merch" />
    <Merch item={ item } />    
    </Layout>
  )
}
