import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"

const Button = styled.button`
  appearance: none;
  border: 0;

  width: 34px;
  height: 34px;

  display: flex;

  align-items: center;
  justify-content: center;

  background: none;

  border: 1px solid ${colors.creamLight};
  border-radius: 50%;

  @media ${breakpoints.desktop} {
    border: 0;
    background: ${colors.cypressDark};

    cursor: pointer;

    &:hover {
      background: ${colors.creamLight};

      svg path {
        stroke: ${colors.firDark};
      }
    }
  }
`

export default function ArrowButton({ onClick, direction = "left" }) {
  return (
    <Button onClick={onClick}>
      {direction === "left" ? (
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13L1 7L7 1"
            stroke="#F8F0D8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 13L7 7L1 1"
            stroke="#F8F0D8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Button>
  )
}
