import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import MerchDetails from "./MerchDetails"
import ProductSlideshow from "../product/ProductSlideshow"
import colors from "../../styles/colors"
import { Context } from "../../provider"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  max-width: 1440px;
  margin: 0 auto;

  position: relative;

  @media ${breakpoints.tablet} {
    flex-direction: row;

    align-items: center;

    &:after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }

    > div {
      height: 100%;
      flex: 1 1 50%;

      padding: 40px;
    }

    > div:last-child {
      border-left: 1px solid ${colors.creamLight};
    }
  }
`

export default function Merch({ item }) {
  return (
    <Context.Consumer>
      {({ addToCart }) => (
        <Wrapper>
          <div>
            <MerchDetails
              title={item?.title}
              description={item?.description?.raw}
              price={item.merch.variants[0].price}
              onAddToCart={() => addToCart(item.id, 'merch')}
            />
          </div>
          <div>
            <div>
              <ProductSlideshow images={item?.merchImages} />
            </div>
          </div>
        </Wrapper>
      )}
    </Context.Consumer>
  )
}
