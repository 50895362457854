import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import ArrowButton from "./ArrowButton"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;

  padding: 0;
  box-sizing: border-box;

  flex: 1 0 50%;

  width: calc(100% - 48px);
  margin: 8px auto;

  @media ${breakpoints.tablet} {
    max-width: 640px;
    width: 100%;
    padding: 0;
  }
`

const Slide = styled.div`
  flex-shrink: 0;
  transition: transform 0.5s ease;

  max-width: 100%;
  width: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const ArrowContainer = styled.div`
  position: absolute;

  display: none;

  left: 28px;
  bottom: 28px;

  gap: 2px;

  @media ${breakpoints.tablet} {
    display: flex;
  }
`

const ThumbnailContainer = styled.div`
  display: flex;
  gap: 2px;
  max-width: 80%;
`

const ThumbnailContainerDesktop = styled.div`
  display: none;
  gap: 2px;
  @media ${breakpoints.tablet} {
    position: absolute;

    right: 28px;
    bottom: 28px;
    display: flex;
  }
`

const MobileControls = styled.div`
  display: flex;

  align-items: center;

  justify-content: center;
  gap: 12px;

  @media ${breakpoints.tablet} {
    display: none;
  }
`

const Thumbnail = styled.div`
  height: 40px;
  width: 40px;

  border: 1px solid ${colors.creamLight};

  .gatsby-image-wrapper {
    height: 40px;
  }
`
export default function ProductSlideshow({ images }) {
  const [current, setCurrent] = useState(0)

  function goForward() {
    if (current >= images.length - 1) {
      setCurrent(0)
    } else {
      setCurrent(current + 1)
    }
  }

  function goBackward() {
    if (current <= 0) {
      setCurrent(images.length - 1)
    } else {
      setCurrent(current - 1)
    }
  }

  return (
    <div>
      <Wrapper>
        {images.map((image, i) => (
          <Slide
            key={i}
            style={{
              transform: `translateX(-${current * (i * 100 - (i - 1) * 100)}%)`,
            }}
          >
            <GatsbyImage image={image.gatsbyImage} alt="Product Image" />
          </Slide>
        ))}

        <ArrowContainer>
          <ArrowButton direction="left" onClick={goBackward} />
          <ArrowButton direction="right" onClick={goForward} />
        </ArrowContainer>

        <ThumbnailContainerDesktop>
          {images.map((image, i) => (
            <Thumbnail key={i} onClick={() => setCurrent(i)}>
              <GatsbyImage image={image.gatsbyImage} alt="Product Image" />
            </Thumbnail>
          ))}
        </ThumbnailContainerDesktop>
      </Wrapper>
      <MobileControls>
        <ArrowButton direction="left" onClick={goBackward} />
        <ThumbnailContainer>
          {images.map((image, i) => (
            <Thumbnail key={i} onClick={() => setCurrent(i)}>
              <GatsbyImage image={image.gatsbyImage} alt="Product Image" />
            </Thumbnail>
          ))}
        </ThumbnailContainer>
        <ArrowButton direction="right" onClick={goForward} />
      </MobileControls>
    </div>
  )
}
